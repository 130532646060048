import React from 'react'
import { Layout } from 'antd'
import { Link } from 'react-router-dom'
import IntlMessages from 'util/IntlMessages'
// import { footerText } from 'util/config'

const Footer = () => {
	return (
		<Layout.Footer>
			<div className='gx-layout-footer-content'>
				<center>
					<p style={{ margin: 0 }}>
						<IntlMessages id='copyright' /> © {new Date().getFullYear()} <a
							href='/#'
							target='_blank'
							rel='noopener noreferrer'
						>
							<IntlMessages id='copyright.company' />
						</a> - <IntlMessages id='copyright.rights' /> <IntlMessages id='copyright.powered' /> <a
							href='https://quanticdynamics.com'
							target='_blank'
							rel='noopener noreferrer'
						>
							<IntlMessages id='copyright.developers' />
						</a>
					</p>
				</center>
				<center>
					<p style={{ paddingTop: '4px' }}>
						<Link
							style={{ paddingRight: '4px' }}
							to={'/terms-and-condition'}
						>
							<IntlMessages id='copyright.terms' />
						</Link>
						<span> | </span>
						<Link
							style={{ paddingLeft: '4px' }}
							to={'/privacy-policy'}
						>
							<IntlMessages id='copyright.privacy' />
						</Link>
					</p>
				</center>
			</div>
		</Layout.Footer>
	)
}
export default Footer
