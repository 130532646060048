import React from 'react'
import { Route, Switch } from 'react-router-dom'

import asyncComponent from '../../util/asyncComponent'

const App = ({ match }) => (
	<div className='gx-main-content'>
		<Switch>
			<Route
				path={`${match.url}/receipt`}
				component={asyncComponent(() => import('./Donations/Receipt'))}
			/>
			<Route
				path={`${match.url}/donation-projects`}
				component={asyncComponent(() => import('./Projects'))}
			/>
			<Route
				path={`${match.url}/donations`}
				component={asyncComponent(() => import('./Donations'))}
			/>
			<Route
				path={`${match.url}/subscriptions`}
				component={asyncComponent(() => import('./Subscriptions'))}
			/>
			<Route
				path={match.url}
				component={asyncComponent(() => import('./Profile'))}
			/>
		</Switch>
	</div>
)

export default App
