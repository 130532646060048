import React from 'react'
import { useSelector } from 'react-redux'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import IntlMessages from '../../util/IntlMessages'

const HorizontalNav = ({ memberType }) => {
	const { pathname } = useSelector(({ common }) => common)
	const selectedKeys = pathname.substr(1)
	const defaultOpenKeys = selectedKeys.split('/')[1]

	const id = pathname.split('/')[2]
	// console.log("id",id);
	return (
		<Menu
			disabledOverflow={[true]}
			defaultOpenKeys={[defaultOpenKeys]}
			selectedKeys={[selectedKeys]}
			mode='horizontal'
		>
			{memberType !== 'admin' ? (
				<>
					<Menu.Item key=''>
						<Link to='/'>
							<IntlMessages id='nav.home' />
						</Link>
					</Menu.Item>
				</>
			) : null}

			{memberType === 'admin' ? (
				<>
					{/* <Menu.Item key='dashboard'>
						<Link to='/admin/home'>
							<IntlMessages id='nav.dashboard' />
						</Link>
					</Menu.Item> */}
					<Menu.Item key='admin/management'>
						<Link to='/admin/management'>
							<IntlMessages id='nav.admin' />
						</Link>
					</Menu.Item>
					<Menu.Item key='admin/donors'>
						<Link to='/admin/donors'>
							<IntlMessages id='nav.donors' />
						</Link>
					</Menu.Item>
					<Menu.Item key='admin/news'>
						<Link to='/admin/news'>
							<IntlMessages id='nav.news' />
						</Link>
					</Menu.Item>
					<Menu.Item key='admin/donations'>
						<Link to='/admin/donations'>
							<IntlMessages id='nav.donations' />
						</Link>
					</Menu.Item>
					<Menu.Item key='admin/products'>
						<Link to='/admin/products'>
							<IntlMessages id='nav.products' />
						</Link>
					</Menu.Item>
				</>
			) : null}

			{memberType !== 'admin' ? (
				<>
					<Menu.Item key='about/introduction'>
						<Link to='/about/introduction'>
							<IntlMessages id='nav.about' />
						</Link>
					</Menu.Item>

					<Menu.Item key={`projects/${id ? id : 'self-relience'}`}>
						<Link to={`/projects/${id ? id : 'self-relience'}`}>
							<IntlMessages id='nav.projects' />
						</Link>
					</Menu.Item>

					<Menu.Item key={`ongoing-projects/${id ? id : '1'}`}>
						<Link to={`/ongoing-projects/${id ? id : '1'}`}>
							<IntlMessages id='nav.ongoing.projects' />
						</Link>
					</Menu.Item>

					<Menu.Item key='membership'>
						<Link to='/membership'>
							<IntlMessages id='nav.membership' />
						</Link>
					</Menu.Item>

					<Menu.Item key='gallery'>
						<Link to='/gallery'>
							<IntlMessages id='nav.gallery' />
						</Link>
					</Menu.Item>

					<Menu.Item key='videos'>
						<Link to='/videos'>
							<IntlMessages id='nav.videos' />
						</Link>
					</Menu.Item>

					<Menu.Item key='volunteer'>
						<Link to='/volunteer'>
							<IntlMessages id='nav.volunteer' />
						</Link>
					</Menu.Item>

					{/* <Menu.Item key='competition'>
						<Link to='/competition/islami-aqidah/2021'>
							<IntlMessages id='nav.siraat' />
						</Link>
					</Menu.Item> */}

					<Menu.Item key='news'>
						<Link to='/news'>
							<IntlMessages id='nav.news' />
						</Link>
					</Menu.Item>

					<Menu.Item key='contact'>
						<Link to='/contact'>
							<IntlMessages id='nav.contact' />
						</Link>
					</Menu.Item>
				</>
			) : null}
		</Menu>
	)
}

HorizontalNav.propTypes = {}

export default HorizontalNav
