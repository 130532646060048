import React from 'react'
import { Route, Switch } from 'react-router-dom'

import asyncComponent from '../../util/asyncComponent'

const App = ({ match }) => (
	<div className='gx-main-content'>
		<Switch>
			<Route
				path={`${match.url}scholarships-for-training-unemployed-unskilled`}
				component={asyncComponent(() =>
					import('./ScholarshipsUnemployedUnskilled')
				)}
			/>

			<Route
				path={`${match.url}ongoing-projects/:id`}
				component={asyncComponent(() => import('./OngoingProjects'))}
			/>
			<Route
				path={`${match.url}self-reliance-project`}
				component={asyncComponent(() => import('./SelfRelianceProject'))}
			/>
			<Route
				path={`${match.url}zakat-calculator`}
				component={asyncComponent(() => import('./ZakatCalculator'))}
			/>
			<Route
				path={`${match.url}about/:tag`}
				component={asyncComponent(() => import('./About'))}
			/>
			<Route
				path={`${match.url}education`}
				component={asyncComponent(() => import('./Education'))}
			/>
			<Route
				path={`${match.url}empowerment`}
				component={asyncComponent(() => import('./Empowerment'))}
			/>
			<Route
				path={`${match.url}humanitarian`}
				component={asyncComponent(() => import('./Humanitarian'))}
			/>
			<Route
				path={`${match.url}donation`}
				component={asyncComponent(() => import('./Donation'))}
			/>
			<Route
				path={`${match.url}gallery`}
				component={asyncComponent(() => import('./Gallery'))}
			/>
			<Route
				path={`${match.url}news`}
				component={asyncComponent(() => import('./News'))}
			/>
			<Route
				path={`${match.url}news-details/:routeExtension`}
				component={asyncComponent(() => import('./NewsDetails'))}
			/>
			<Route
				path={`${match.url}membership`}
				component={asyncComponent(() => import('./Membership'))}
			/>
			<Route
				path={`${match.url}volunteer/confirmation`}
				component={asyncComponent(() => import('./Volunteer/Confirmation'))}
			/>
			<Route
				path={`${match.url}volunteer`}
				component={asyncComponent(() => import('./Volunteer'))}
			/>
			{/* <Route
				path={`${match.url}competition/:competitionName/:year`}
				component={asyncComponent(() => import('./Competition'))}
			/> */}
			<Route
				path={`${match.url}projects/:tag`}
				component={asyncComponent(() => import('./Projects'))}
			/>
			<Route
				path={`${match.url}donate/:routeExtension`}
				component={asyncComponent(() => import('./Donate'))}
			/>
			<Route
				path={`${match.url}payment/competition`}
				component={asyncComponent(() => import('./Competition/Payment'))}
			/>
			<Route
				path={`${match.url}payment`}
				component={asyncComponent(() => import('./Payment'))}
			/>
			<Route
				path={`${match.url}videos`}
				component={asyncComponent(() => import('./Videos'))}
			/>
			<Route
				path={`${match.url}terms-and-condition`}
				component={asyncComponent(() => import('./Terms'))}
			/>
			<Route
				path={`${match.url}privacy-policy`}
				component={asyncComponent(() => import('./Privacy'))}
			/>
			<Route
				path={`${match.url}error404`}
				component={asyncComponent(() => import('./404'))}
			/>
			<Route
				path={`${match.url}contact`}
				component={asyncComponent(() => import('./Contacts'))}
			/>
			<Route
				path={match.url}
				component={asyncComponent(() => import('./Home'))}
			/>
		</Switch>
	</div>
)

export default App
