import React from 'react'
import { Route, Switch } from 'react-router-dom'

import asyncComponent from '../../util/asyncComponent'

const App = ({ match }) => (
	<div className='gx-main-content'>
		<Switch>
			<Route
				path={`${match.url}/donors`}
				component={asyncComponent(() => import('./Donors'))}
			/>
			<Route
				path={`${match.url}/donations`}
				component={asyncComponent(() => import('./Donations'))}
			/>
			<Route
				path={`${match.url}/news`}
				component={asyncComponent(() => import('./News'))}
			/>
			<Route
				path={`${match.url}/management`}
				component={asyncComponent(() => import('./Admin'))}
			/>
			<Route
				path={`${match.url}/system`}
				component={asyncComponent(() => import('./System'))}
			/>

			<Route
				path={`${match.url}/products`}
				component={asyncComponent(() => import('./Products'))}
			/>
			{/* <Route
				path={match.url}
				component={asyncComponent(() => import('./Home'))}
			/> */}
		</Switch>
	</div>
)

export default App
