import RequestService from './Request'
import ClockService from './../Clock'
import UserAuthService from './../user/Auth'

const login = (username, password) => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/token/admin/login',
			method: 'POST',
			body: {
				username: username,
				password: password
			},
			addToken: false
		}
		RequestService.send(req)
			.then(res => {
				if (res.state === 'success') {
					if (res.admin) {
						localStorage.setItem('admin', JSON.stringify(res.admin))
						localStorage.setItem('adminKey', res.token)
						localStorage.setItem(
							'adminKeyExp',
							(new Date().getTime() + 24 * 60 * 60 * 1000).toString()
						)
						localStorage.setItem('adminReturned', res.admin.email)
						localStorage.removeItem('superAdmin')
						localStorage.removeItem('superAdminKey')
						localStorage.removeItem('superAdminKeyExp')
						localStorage.removeItem('superAdminReturned')
					} else {
						localStorage.setItem('superAdmin', JSON.stringify(res.superAdmin))
						localStorage.setItem('superAdminKey', res.token)
						localStorage.setItem(
							'superAdminKeyExp',
							(new Date().getTime() + 24 * 60 * 60 * 1000).toString()
						)
						localStorage.setItem('superAdminReturned', res.superAdmin.username)
						localStorage.removeItem('admin')
						localStorage.removeItem('adminKey')
						localStorage.removeItem('adminKeyExp')
						localStorage.removeItem('adminReturned')
					}
					UserAuthService.logout()
				}
				return resolve(res)
			})
			.catch(err => {
				return reject(err)
			})
	})
}

const resetPassword = (token, password) => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/token/admin/reset',
			method: 'POST',
			body: {
				token: token,
				password: password
			},
			addToken: false
		}
		RequestService.send(req)
			.then(res => {
				if (res.state === 'success') {
					localStorage.setItem('admin', JSON.stringify(res.admin))
					localStorage.setItem('adminKey', res.token)
					localStorage.setItem(
						'adminKeyExp',
						(new Date().getTime() + 120 * 60 * 1000).toString()
					)
					localStorage.setItem('adminReturned', res.admin.email)
					localStorage.removeItem('superAdmin')
					localStorage.removeItem('superAdminKey')
					localStorage.removeItem('superAdminKeyExp')
					localStorage.removeItem('superAdminReturned')
					UserAuthService.logout()
				}
				return resolve(res)
			})
			.catch(err => {
				return reject(err)
			})
	})
}

const sendEmailVerificationLink = email => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/token/admin/link',
			method: 'POST',
			body: {
				email: email
			},
			addToken: false
		}
		RequestService.send(req)
			.then(res => {
				return resolve(res)
			})
			.catch(err => {
				return reject(err)
			})
	})
}

const verifyEmailLinkData = token => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/token/admin/verify',
			method: 'POST',
			body: {
				token: token
			},
			addToken: false
		}
		RequestService.send(req)
			.then(res => {
				return resolve(res)
			})
			.catch(err => {
				return reject(err)
			})
	})
}

const logout = () => {
	localStorage.removeItem('admin')
	localStorage.removeItem('adminKey')
	localStorage.removeItem('adminKeyExp')
	localStorage.removeItem('superAdmin')
	localStorage.removeItem('superAdminKey')
	localStorage.removeItem('superAdminKeyExp')
}

const status = () => {
	const admin = localStorage.getItem('admin')
	const adminKey = localStorage.getItem('adminKey')
	const adminKeyExp = localStorage.getItem('adminKeyExp')
	const superAdmin = localStorage.getItem('superAdmin')
	const superAdminKey = localStorage.getItem('superAdminKey')
	const superAdminKeyExp = localStorage.getItem('superAdminKeyExp')

	if (
		(admin &&
			adminKey &&
			adminKeyExp &&
			parseInt(adminKeyExp) >= ClockService.now()) ||
		(superAdmin &&
			superAdminKey &&
			superAdminKeyExp &&
			parseInt(superAdminKeyExp) >= ClockService.now())
	) {
		return true
	}
	return false
}

export default {
	login,
	resetPassword,
	sendEmailVerificationLink,
	verifyEmailLinkData,
	logout,
	status
}
