import bnMessages from '../locales/bn-BD.json'

const BnLang = {
	messages: {
		...bnMessages
	},
	antd: null,
	locale: 'bn-BD'
}
export default BnLang
