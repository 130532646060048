import React, { useState, useEffect, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Button, message } from 'antd'
import LoadingBar from 'react-top-loading-bar'
import IntlMessages from 'util/IntlMessages'
import { injectIntl } from 'react-intl'
import AuthService from './../services/user/Auth'

const VerifyUserEmail = ({ intl }) => {
	const loadingRef = useRef(null)
	const history = useHistory()
	const [verifyStatus, setVerifyStatus] = useState('loading')
	const { token } = useParams()

	useEffect(() => {
		loadingRef.current.continuousStart()
		AuthService.loginByLink(token.toString()).then(
			res => {
				loadingRef.current.complete()
				setVerifyStatus(res.state)
				if (res.state === 'success') {
					setTimeout(() => {
						history.push('/user')
					}, 3000)
				} else {
					message.warning(intl.formatMessage({ id: res.message }))
				}
			},
			err => {
				loadingRef.current.complete()
				console.error(err)
				message.warning(intl.formatMessage({ id: err.message }))
			}
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className='gx-login-container'>
			<LoadingBar height={3} color='#0C8F4D' ref={loadingRef} />
			<div className='gx-login-content'>
				<div className='gx-login-header'>
					<img
						src={require('assets/images/logo-white.png')}
						alt='GDIC'
						title='GDIC'
					/>
				</div>
				{verifyStatus === 'loading' ? (
					<div className='gx-mb-4'>
						<h2>
							<IntlMessages id='app.userAuth.verifyUserEmail.Verifying' />
						</h2>
					</div>
				) : null}
				{verifyStatus === 'failure' ? (
					<div className='gx-mb-4'>
						<h2>
							<IntlMessages id='app.userAuth.verifyUserEmail.Failure' />
						</h2>
						<p>
							<IntlMessages id='app.userAuth.verifyUserEmail.Failure.message' />
						</p>
						<Button
							type='primary'
							className='gx-mb-0'
							onClick={() => {
								history.push('/')
							}}
						>
							<IntlMessages id='app.userAuth.verifyUserEmail.Failure.close' />
						</Button>
					</div>
				) : null}
				{verifyStatus === 'success' ? (
					<div className='gx-mb-4'>
						<h2 className='text-danger'>
							<IntlMessages id='app.userAuth.verifyUserEmail.Success' />
						</h2>
						<p>
							<IntlMessages id='app.userAuth.verifyUserEmail.Success.message' />
						</p>
					</div>
				) : null}
			</div>
		</div>
	)
}

export default injectIntl(VerifyUserEmail)
