import React, { useState, useRef, useEffect } from 'react'
import { Button, Layout } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
// import CustomScrollbars from 'util/CustomScrollbars'
import languageData from '../languageData'
import HorizontalNav from '../HorizontalNav'
import UserSecondaryNav from '../UserSecondaryNav'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
	switchLanguage,
	toggleCollapsedSideNav
} from '../../../appRedux/actions/Setting'
import IntlMessages from '../../../util/IntlMessages'

import UserAuthService from '../../../services/user/Auth'
import AdminAuthService from '../../../services/admin/Auth'
import DimensionService from './../../../services/Dimensions'
import {
	YoutubeFilled,
	FacebookFilled,
	PhoneFilled,
	MailFilled
} from '@ant-design/icons'

const { Header } = Layout

const HorizontalDefault = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { width } = DimensionService.useDimensions()
	const pageRef = useRef(null)
	const { pathname } = useLocation()
	const locale = useSelector(({ settings }) => settings.locale)
	const { navCollapsed } = useSelector(({ common }) => common)
	const [isLoggedIn, setIsLoggedIn] = useState(false)
	const [loggedInMember, setLoggedInMember] = useState('none')

	useEffect(() => {
		if (UserAuthService.status()) {
			setIsLoggedIn(true)
			setLoggedInMember('user')
		} else if (AdminAuthService.status()) {
			setIsLoggedIn(true)
			setLoggedInMember('admin')
		}
		setTimeout(() => {
			pageRef.current.scrollIntoView()
		}, 20)
	}, [pathname])

	// const languageMenu = () => (
	// 	<CustomScrollbars className='gx-popover-lang-scroll'>
	// 		<ul className='gx-sub-popover'>
	// 			{languageData.map(language => (
	// 				<li
	// 					className='gx-media gx-pointer'
	// 					key={JSON.stringify(language)}
	// 					onClick={e => dispatch(switchLanguage(language))}
	// 				>
	// 					<i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
	// 					<span className='gx-language-text'>{language.name}</span>
	// 				</li>
	// 			))}
	// 		</ul>
	// 	</CustomScrollbars>
	// )

	const enSelected = () => {
		dispatch(switchLanguage(languageData[0]))
		localStorage.setItem('language', JSON.stringify(languageData[0]))
	}

	const bnSelected = () => {
		dispatch(switchLanguage(languageData[1]))
		localStorage.setItem('language', JSON.stringify(languageData[1]))
	}

	const onLogOut = () => {
		if (loggedInMember === 'user') {
			UserAuthService.logout()
			setIsLoggedIn(false)
			setLoggedInMember('none')
			history.push('/')
		} else if (loggedInMember === 'admin') {
			AdminAuthService.logout()
			setIsLoggedIn(false)
			setLoggedInMember('none')
			history.push('/')
		}
	}

	return (
		<div className='gx-header-horizontal' ref={pageRef}>
			<div className='gx-header-horizontal-top'>
				<div className='gx-container header-icons-container'>
					{/* <div className='my-account-btn' >
						{!isLoggedIn ? (
							<Button
								// size='large'
								type='link'
								// type='primary'
								onClick={() => {
									history.push('/user/signin')
								}}
							>
								<IntlMessages id='signIn' />
							</Button>
						) : (
								<Button type='link' onClick={onLogOut}>
									<IntlMessages id='signOut' />
								</Button>
							)}
					</div> */}
					<a
						className='social-icon'
						rel='noopener noreferrer'
						target='_blank'
						href='https://www.facebook.com/assunnahfoundationbd'
						// style={{
						// 	top: width > 575 ? '-2px' : '0px'
						// }}
					>
						<FacebookFilled style={{ fontSize: '20px', color: '#4267B2' }} />
					</a>
					<a
						className='social-icon'
						rel='noopener noreferrer'
						target='_blank'
						href='https://www.youtube.com/channel/UCuxth2BimHUigZ344JhcFPw'
						// style={{
						// 	top: width > 575 ? '-2px' : '0px'
						// }}
					>
						<YoutubeFilled style={{ fontSize: '20px', color: '#FF0000' }} />
					</a>

					<a
						className='social-icon'
						rel='noopener noreferrer'
						target='_blank'
						href='https://www.youtube.com/channel/UCuxth2BimHUigZ344JhcFPw'
						// style={{
						// 	top: width > 575 ? '-2px' : '0px'
						// }}
					>
						<MailFilled style={{ fontSize: '20px', color: '#008E48' }} />
					</a>

					<a
						className='social-icon'
						rel='noopener noreferrer'
						spin='true'
						target='_blank'
						href='https://www.youtube.com/channel/UCuxth2BimHUigZ344JhcFPw'
						// style={{
						// 	top: width > 575 ? '-0px' : '0px'
						// }}
					>
						<PhoneFilled
							rotate='90'
							style={{ fontSize: '20px', color: '#008E48' }}
						/>
					</a>

					<div className='ant-btn-group lang-btn'>
						<Button
							size='small'
							type={locale.locale === 'en' ? 'primary' : 'default'}
							onClick={enSelected}
						>
							EN
						</Button>
						<Button
							size='small'
							type={locale.locale === 'en' ? 'default' : 'primary'}
							onClick={bnSelected}
						>
							বাংলা
						</Button>
					</div>
				</div>
			</div>

			<Header className='gx-header-horizontal-main'>
				<div className='gx-container'>
					<div className='gx-header-horizontal-main-flex'>
						<div
							style={{ position: 'relative', bottom: '5px' }}
							className='gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3'
						>
							<i
								className='gx-icon-btn icon icon-menu'
								onClick={() => {
									dispatch(toggleCollapsedSideNav(!navCollapsed))
								}}
							/>
						</div>
						<Link
							style={{ position: 'relative', bottom: '5px' }}
							to='/'
							className='gx-d-block gx-d-lg-none gx-pointer gx-w-logo'
						>
							<img
								alt='logo'
								width='55px'
								height='35px'
								src={require('assets/images/w-logo.png')}
							/>
						</Link>
						<Link
							style={{ position: 'relative', bottom: '5px' }}
							to='/'
							className='gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo'
						>
							<img
								alt='logo-white'
								src={require('assets/images/logo-white.png')}
							/>
						</Link>
						{/* <div className='gx-header-search gx-d-none gx-d-lg-flex'>
              <SearchBox styleName='gx-lt-icon-search-bar-lg'
                         placeholder='Search in app...'
                         onChange={updateSearchChatUser}
                         value={searchText}/>

              <Select defaultValue='lucy' style={{width: 120}} onChange={handleChange}>
                <Option value='jack'>Products</Option>
                <Option value='lucy'>Apps</Option>
                <Option value='Yiminghe'>Blogs</Option>
              </Select>
            </div> */}

						<ul className='gx-header-notifications gx-ml-auto'>
							{/* <li className='gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none'>
								<Popover overlayClassName='gx-popover-horizantal' placement='bottomRight' content={
									<div className='gx-d-flex'>
										<Dropdown overlay={menu}>
											<Button>
												Category <Icon type='down' />
											</Button>
										</Dropdown>
										<SearchBox styleName='gx-popover-search-bar'
											placeholder='Search in app...'
											onChange={updateSearchChatUser}
											value={searchText} />
									</div>
								} trigger='click'>
									<span className='gx-pointer gx-d-block'><i className='icon icon-search-new' /></span>

								</Popover>
							</li> */}
							{/* <li className='gx-notify'>
                <Popover overlayClassName='gx-popover-horizantal' placement='bottomRight' content={<AppNotification/>}
                         trigger='click'>
                  <span className='gx-pointer gx-d-block'><i className='icon icon-notification'/></span>
                </Popover>
              </li> */}

							{/* <li className='gx-msg'>
                <Popover overlayClassName='gx-popover-horizantal' placement='bottomRight'
                         content={<MailNotification/>} trigger='click'>
                <span className='gx-pointer gx-status-pos gx-d-block'>
                <i className='icon icon-chat-new'/>
                <span className='gx-status gx-status-rtl gx-small gx-orange'/>
                </span>
                </Popover>
              </li> */}
							<li
								style={{ position: 'relative', bottom: '8px' }}
								className='gx-header-notifications gx-language'
							>
								{/* <Popover
									size='large'
									overlayClassName='gx-popover-horizantal'
									placement='bottomRight'
									content={languageMenu()}
									trigger='click'
								>
									<span className='gx-pointer gx-flex-row gx-align-items-center'>
										<i className={`flag flag-24 flag-${locale.icon}`} />
									</span>
								</Popover> */}
							</li>
							{!isLoggedIn ? (
								<Button
									size={
										width < 390 ? 'small' : width < 769 ? 'middle' : 'large'
									}
									type='primary'
									onClick={() => {
										history.push('/user/signin')
									}}
								>
									<IntlMessages id='signIn' />
								</Button>
							) : (
								<Button
									size={
										width < 390 ? 'small' : width < 769 ? 'middle' : 'large'
									}
									type='danger'
									onClick={onLogOut}
								>
									<IntlMessages id='signOut' />
								</Button>
							)}
							<Button
								size={width < 390 ? 'small' : width < 769 ? 'middle' : 'large'}
								type='primary'
								onClick={() => {
									history.push('/donation')
								}}
							>
								<IntlMessages id='payment.info.donateButton' />
							</Button>

							{/* <li className='gx-user-nav'><UserInfo/></li> */}
						</ul>
					</div>
				</div>
			</Header>
			<div className='gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block'>
				<div className='gx-container'>
					<div className='gx-header-horizontal-nav-flex'>
						<HorizontalNav memberType={loggedInMember} />
					</div>
				</div>
			</div>
			<div className='gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block' style={{ background: '#fff' }}>
				<div className='gx-container'>
					<div className='gx-header-horizontal-nav-flex'>
						<UserSecondaryNav memberType={loggedInMember} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default HorizontalDefault
