import React, { useState, useEffect, useRef } from 'react'
import { Button, message, Input, Form } from 'antd'
import { useHistory } from 'react-router-dom'
import LoadingBar from 'react-top-loading-bar'
import IntlMessages from 'util/IntlMessages'
import { injectIntl } from 'react-intl'
import AuthService from './../services/user/Auth'

const phoneRegex = new RegExp(/(^(\+88)?(01){1}[3456789]{1}(\d){8})$/)
// eslint-disable-next-line no-useless-escape
const emailRegex = new RegExp(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/)
const phoneOrEmailRegex = new RegExp(
	phoneRegex.source + '|' + emailRegex.source
)

const SignIn = ({ intl }) => {
	const history = useHistory()
	const loadingRef = useRef(null)
	const [loading, setLoading] = useState(false)
	const idField = useRef(null)
	const otpField = useRef(null)
	const [otpSent, setOtpSent] = useState(false)

	useEffect(() => {
		if (AuthService.status()) {
			history.push('/user')
		}
		idField.current.focus({
			cursor: 'all'
		})
	}, [history])

	const onSendOTP = values => {
		if (!loading) {
			setLoading(true)
			loadingRef.current.continuousStart()
			AuthService.loginInit(values.phoneOrEmail).then(
				res => {
					setLoading(false)
					loadingRef.current.complete()
					if (res.state === 'success') {
						message.success(intl.formatMessage({ id: res.message }))
						if (res.idType === 'mobileNo') {
							setOtpSent(true)
							otpField.current.focus({
								cursor: 'all'
							})
						} else {
							history.push('/')
						}
					} else {
						message.warning(intl.formatMessage({ id: res.message }))
					}
				},
				err => {
					setLoading(false)
					loadingRef.current.complete()
					console.error(err)
					message.warning(intl.formatMessage({ id: err.message }))
				}
			)
		}
	}

	const onFinish = values => {
		if (!loading) {
			setLoading(true)
			loadingRef.current.continuousStart()
			AuthService.loginByOTP(values.phoneOrEmail, values.otp).then(
				res => {
					setLoading(false)
					loadingRef.current.complete()
					if (res.state === 'success') {
						history.push('/user')
						message.success(intl.formatMessage({ id: res.message }))
					} else {
						message.warning(intl.formatMessage({ id: res.message }))
					}
				},
				err => {
					setLoading(false)
					loadingRef.current.complete()
					console.error(err)
					message.warning(intl.formatMessage({ id: err.message }))
				}
			)
		}
	}

	const onCancel = () => {
		setOtpSent(false)
		idField.current.focus({
			cursor: 'all'
		})
	}

	return (
		<div className='gx-app-login-wrap'>
			<LoadingBar height={3} color='#0C8F4D' ref={loadingRef} />
			<div className='gx-app-login-container'>
				<div className='gx-app-login-main-content'>
					<div className='gx-app-logo-content'>
						<div className='gx-app-logo-content-bg'>
							<img
								src={'https://apps.green-delta.com/img/admin/login-image.jpg'}
								alt='Login'
							/>
						</div>
						<div className='gx-app-logo-wid'>
							<h1>
								<IntlMessages id='app.userAuth.signIn' />
							</h1>
							<p>
								<IntlMessages id='app.userAuth.bySigning' />
							</p>
							<p>
								<IntlMessages id='app.userAuth.getAccount' />
							</p>
						</div>
						<div className='gx-app-logo'>
							<img alt='example' src={require('assets/images/logo.png')} />
						</div>
					</div>
					<div className='gx-app-login-content'>
						<Form
							initialValues={{ remember: true }}
							name='basic'
							onFinish={otpSent ? onFinish : onSendOTP}
							// onFinishFailed={onFinishFailed}
							className='gx-signin-form gx-form-row0'
						>
							<Form.Item
								rules={[
									{
										required: true,
										pattern: phoneOrEmailRegex,
										message: intl.formatMessage({
											id: 'quick.donation.phoneOrEmail.message'
										})
									}
								]}
								name='phoneOrEmail'
							>
								<Input
									ref={idField}
									disabled={otpSent}
									placeholder={intl.formatMessage({
										id: 'quick.donation.phoneOrEmail.placeholder'
									})}
								/>
							</Form.Item>
							{otpSent ? (
								<Form.Item
									rules={[
										{
											required: true,
											min: 6,
											max: 6,
											message: intl.formatMessage({ id: 'otp.message' })
										}
									]}
									name='otp'
								>
									<Input
										ref={otpField}
										type='password'
										min={6}
										max={6}
										placeholder='OTP'
									/>
								</Form.Item>
							) : null}
							{/* <Form.Item>
								<Checkbox><IntlMessages id='appModule.iAccept' /></Checkbox>
								<span className='gx-signup-form-forgot gx-link'><IntlMessages
									id='appModule.termAndCondition' /></span>
							</Form.Item> */}
							<br></br>
							<br></br>
							<Form.Item>
								{!otpSent ? (
									<Button type='primary' className='gx-mb-0' htmlType='submit'>
										<IntlMessages id='app.userAuth.sendOTP' />
									</Button>
								) : (
									<Button type='primary' className='gx-mb-0' htmlType='submit'>
										<IntlMessages id='app.userAuth.signIn' />
									</Button>
								)}
								<Button type='danger' className='gx-mb-0' onClick={onCancel}>
									<IntlMessages id='app.userAuth.reset' />
								</Button>
								<Button
									type='primary'
									className='gx-mb-0'
									onClick={() => {
										history.push('/')
									}}
								>
									<IntlMessages id='app.userAuth.verifyUserEmail.Failure.close' />
								</Button>
							</Form.Item>
						</Form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default injectIntl(SignIn)
