import { SWITCH_LANGUAGE } from 'constants/ActionTypes'
import {
	LAYOUT_TYPE,
	LAYOUT_TYPE_FULL,
	NAV_STYLE,
	THEME_COLOR,
	THEME_TYPE,
	THEME_TYPE_LITE,
	NAV_STYLE_DEFAULT_HORIZONTAL
} from '../../constants/ThemeSetting'

const defaultLanguage = {
	languageId: 'bengali',
	locale: 'bn',
	name: 'Bengali',
	icon: 'bd'
}
const language = JSON.parse(localStorage.getItem('language'))

if(!language) {
	localStorage.setItem('language', JSON.stringify(defaultLanguage))
}

const initialSettings = {
	navStyle: NAV_STYLE_DEFAULT_HORIZONTAL,
	layoutType: LAYOUT_TYPE_FULL,
	themeType: THEME_TYPE_LITE,
	themeColor: THEME_COLOR,

	isDirectionRTL: false,
	locale: language ? language : defaultLanguage
}

const settings = (state = initialSettings, action) => {
	switch (action.type) {
		case THEME_TYPE:
			return {
				...state,
				themeType: action.themeType
			}
		case THEME_COLOR:
			console.log('yes', action.themeColor)
			return {
				...state,
				themeColor: action.themeColor
			}

		case NAV_STYLE:
			return {
				...state,
				navStyle: action.navStyle
			}
		case LAYOUT_TYPE:
			return {
				...state,
				layoutType: action.layoutType
			}

		case SWITCH_LANGUAGE:
			return {
				...state,
				locale: action.payload
			}
		default:
			return state
	}
}

export default settings
