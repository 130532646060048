const languageData = [
	{
		languageId: 'english',
		locale: 'en',
		name: 'English',
		icon: 'us'
	},
	{
		languageId: 'bengali',
		locale: 'bn',
		name: 'Bengali',
		icon: 'bd'
	}
]
export default languageData
