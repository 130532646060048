import React from 'react'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'

import CustomScrollbars from 'util/CustomScrollbars'
import SidebarLogo from './SidebarLogo'
import IntlMessages from '../../util/IntlMessages'

const SidebarContent = ({ memberType }) => {
	return (
		<>
			<SidebarLogo />
			<div className='gx-sidebar-content'>
				<CustomScrollbars className='gx-layout-sider-scrollbar'>
					<Menu
						// defaultOpenKeys={[defaultOpenKeys]}
						// selectedKeys={[selectedKeys]}
						theme={'lite'}
						mode='inline'
					>
						{/* <Menu.Item key='sample'>
						<Link style={{ color: '#008E48' }} to='/sample'>
							<span><IntlMessages id='sidebar.samplePage' /></span>
						</Link>
					</Menu.Item>

					<Menu.Item key='sample2'>
						<Link style={{ color: '#008E48' }} to='/sample2'>
							<span><IntlMessages id='sidebar.samplePage' /></span>
						</Link>
					</Menu.Item> */}

						{memberType !== 'admin' ? (
							<>
								<Menu.Item key='home'>
									<Link style={{ color: '#008E48' }} to='/'>
										<IntlMessages id='nav.home' />
									</Link>
								</Menu.Item>
							</>
						) : null}

						{memberType === 'user' ? (
							<>
								<Menu.Item key='userProfile'>
									<Link style={{ color: '#008E48' }} to='/user'>
										<IntlMessages id='nav.profile' />
									</Link>
								</Menu.Item>
								<Menu.Item key='userDonations'>
									<Link style={{ color: '#008E48' }} to='/user/donations'>
										<IntlMessages id='nav.donations' />
									</Link>
								</Menu.Item>
								<Menu.Item key='userSubscriptions'>
									<Link style={{ color: '#008E48' }} to='/user/subscriptions'>
										<IntlMessages id='nav.subscriptions' />
									</Link>
								</Menu.Item>
								<Menu.Item key='donation-projects'>
									<Link
										style={{ color: '#008E48' }}
										to='/user/donation-projects'
									>
										<IntlMessages id='nav.donation.projects' />
									</Link>
								</Menu.Item>
							</>
						) : null}

						{memberType === 'admin' ? (
							<>
								<Menu.Item key='dashboard'>
									<Link style={{ color: '#008E48' }} to='/admin'>
										<IntlMessages id='nav.dashboard' />
									</Link>
								</Menu.Item>
								<Menu.Item key='admin'>
									<Link to='/admin/management'>
										<IntlMessages id='nav.admin' />
									</Link>
								</Menu.Item>
								<Menu.Item key='donors'>
									<Link style={{ color: '#008E48' }} to='/admin/donors'>
										<IntlMessages id='nav.donors' />
									</Link>
								</Menu.Item>
								<Menu.Item key='news'>
									<Link style={{ color: '#008E48' }} to='/admin/news'>
										<IntlMessages id='nav.news' />
									</Link>
								</Menu.Item>
							</>
						) : null}

						{memberType !== 'admin' ? (
							<>
								<Menu.Item key='about'>
									<Link style={{ color: '#008E48' }} to='/about/introduction'>
										<IntlMessages id='nav.about' />
									</Link>
								</Menu.Item>

								{/* <Menu.Item key='projects'>
									<Link style={{ color: '#008E48' }} to='/projects'>
										<IntlMessages id='nav.projects' />
									</Link>
								</Menu.Item> */}

								<Menu.Item key='projects'>
									<Link
										style={{ color: '#008E48' }}
										to='/projects/self-relience'
									>
										<IntlMessages id='nav.projects' />
									</Link>
								</Menu.Item>

								<Menu.Item key='membership'>
									<Link style={{ color: '#008E48' }} to='/membership'>
										<IntlMessages id='nav.membership' />
									</Link>
								</Menu.Item>

								<Menu.Item key='gallery'>
									<Link style={{ color: '#008E48' }} to='/gallery'>
										<IntlMessages id='nav.gallery' />
									</Link>
								</Menu.Item>

								<Menu.Item key='video'>
									<Link style={{ color: '#008E48' }} to='/videos'>
										<IntlMessages id='nav.videos' />
									</Link>
								</Menu.Item>

								<Menu.Item key='volunteer'>
									<Link style={{ color: '#008E48' }} to='/volunteer'>
										<IntlMessages id='nav.volunteer' />
									</Link>
								</Menu.Item>

								<Menu.Item key='news'>
									<Link style={{ color: '#008E48' }} to='/news'>
										<IntlMessages id='nav.news' />
									</Link>
								</Menu.Item>

								<Menu.Item key='contact'>
									<Link style={{ color: '#008E48' }} to='/contact'>
										<IntlMessages id='nav.contact' />
									</Link>
								</Menu.Item>
							</>
						) : null}
					</Menu>
				</CustomScrollbars>
			</div>
		</>
	)
}

SidebarContent.propTypes = {}

export default SidebarContent
